<template>
  <div id="Condition-page" class="service-condition">
    <div class="container">
      <div class="header"></div>
      <div class="body">
        <ul>
          <!-- 1. 날짜 -->
          <li :class="{ activated: activatedListItem === 'date' }" ref="date_start">
            <div class="one-list">
              <div class="tab" @click="onClickListItem('date')">
                <div class="key">
                  <img src="@/assets/icons/svg/calendar.svg" />
                  <span>{{ displaySelectedDate }}</span>
                </div>
                <div class="value">
                  <button type="button">
                    <i class="fa fa-angle-down"></i>
                  </button>
                </div>
              </div>
              <div class="transitioned-item">
                <div class="container">
                  <ui-day-picker calendarType="date" v-model="formData.date.value" :min="formData.date.min" @select="onSelectDate" />
                </div>
              </div>
            </div>
          </li>
          <!-- 2. 시간-->
          <li :class="{ activated: activatedListItem === 'time' }" ref="date_end">
            <div class="one-list">
              <div class="tab" @click="onClickListItem('time')">
                <div class="key">
                  <img src="@/assets/icons/svg/icon_clock.svg" />
                  <span>{{ displaySelectedTime }}</span>
                </div>
                <div class="value">
                  <button type="button">
                    <i class="fa fa-angle-down"></i>
                  </button>
                </div>
              </div>
              <div class="transitioned-item">
                <div class="container">
                  <ul>
                    <li v-if="!displayProducts || displayProducts.length === 0" class="message">
                      <span>{{ $__t('날짜를 먼저 선택해주세요.') }}</span>
                    </li>

                    <li
                      v-else
                      v-for="(product, index) in displayProducts"
                      :key="index"
                      :class="{
                        selected: product.selected,
                        disabled: product.disabled
                      }"
                      @click="onSelectProduct(product)"
                    >
                      <div class="time">
                        {{ `${$moment(product.start_date).format('HH:mm')} - ${$moment(product.end_date).format('HH:mm')}` }}
                      </div>
                      <div class="quantity">남은수량 : {{ product.quantity }}개</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="footer_and_message">
        <div class="footer">
          <button
            class="button-area"
            :class="{
              activated: formData.date.value && formData.product.value
            }"
            type="button"
            @click="onClickSubmit"
          >
            {{
              $__t(`{price}원 결제하기`, {
                price: $utils.numberFormat(service.product_price)
              })
            }}
          </button>
        </div>
      </div>
    </div>

    <guide-modal id="priceLuggageModal" ref="priceLuggageModal" :noCloseButton="true" :noBgClick="true" :external-close="true">
      <template v-slot:body>
        <price />
      </template>
    </guide-modal>

    <ui-slider id="estimated-price-slider" ref="estimated-price-slider" :bgClick="true"> </ui-slider>
  </div>
</template>

<script>
import _ from 'lodash';
import Price from '@/components/pages/_/Price';
import EstimatedPrice from '@/components/includes/EstimatedPrice';
import UiSlider from '@/components/modules/Slider/Body.vue';

export default {
  props: ['store', 'service'],
  components: {},
  data() {
    return {
      activatedListItem: '',

      products: [],

      formData: {
        date: {
          // value: null,
          value: this.$moment(),
          min: this.$moment().startOf('day')
        },
        product: {
          value: null
        }
      }
    };
  },

  computed: {
    displaySelectedDate() {
      if (!this.formData.date.value) {
        return '날짜 선택';
      }
      let result = '';
      result = this.$moment(this.formData.date.value).format('YYYY.MM.DD');
      let day = this.$moment(this.formData.date.value).day();
      switch (day) {
        case 1:
          result = result + ' (월)';
          break;

        case 2:
          result = result + ' (화)';
          break;

        case 3:
          result = result + ' (수)';
          break;

        case 4:
          result = result + ' (목)';
          break;
        case 5:
          result = result + ' (금)';
          break;
        case 6:
          result = result + ' (토)';
          break;
        case 7:
          result = result + ' (일)';
          break;
      }
      this.bookingDate = result;
      return result;
    },

    displaySelectedTime() {
      if (!this.formData.product.value) {
        return '시간 선택';
      }

      const product = this.formData.product.value;
      return `${this.$moment(product.start_date).format('HH:mm')} - ${this.$moment(product.end_date).format('HH:mm')}`;
    },

    productSelectedIndex() {
      if (!this.$data.formData.product.value) {
        return -1;
      }

      return this.$data.formData.product.value?.index;
    },

    displayProducts() {
      let items = [];
      let index = this.productSelectedIndex;
      // console.log('products', this.products);

      this.products.forEach((product, i) => {
        items.push({
          ...product,
          index: i,
          selected: i === index,
          disabled: product.quantity < 1 || this.$moment.duration(this.$moment().diff(this.$moment(product.start_date))).asMilliseconds() > 0
        });
      });

      return items;
    }
  },

  created() {},
  async mounted() {
    await this.onSelectDate();
  },

  methods: {
    onClickListItem(item) {
      switch (item) {
        case 'date':
          this.activatedListItem = this.activatedListItem === 'date' ? '' : 'date';
          break;

        case 'time':
          this.activatedListItem = this.activatedListItem === 'time' ? '' : 'time';
          break;
      }
    },

    fetchProducts(options) {
      return this.$store.dispatch('serviceProduct/getProducts', {
        page: 1,
        size: 10,
        ...options
      });
    },

    async onSelectDate(day) {
      const date = this.$moment(day).format('YYYY-MM-DD');
      const { items } = await this.fetchProducts({
        service_id: this.service.uid,
        day: date
      });
      // console.log('items', items);
      this.$data.formData.product.value = null;
      this.$data.products = items.map((item) => {
        const timeComponents = item.time_table.split(',');

        this.activatedListItem = this.activatedListItem === 'date' ? '' : 'date';

        return {
          ...item,
          product_id: item.uid,
          start_date: `${date} ${timeComponents[0]}`,
          end_date: `${date} ${timeComponents[1]}`,
          quantity: item.stock_remain
        };
      });
    },

    onSelectProduct(product) {
      // console.log('product', product);
      if (product.disabled) {
        // @TODO: 사용할 수 없음을 안내
        return;
      }

      this.$data.formData.product.value = product;
      this.activatedListItem = this.activatedListItem === 'time' ? '' : 'time';
    },

    onClickSubmit: _.debounce(function () {
      const product = this.$data.formData.product.value;

      // @MEWO: 해당 Condition 의 역할은 예약하기 위한 데이터를 얻어오는것 까지임
      this.$emit('submit', product);
    }, 100)
  },

  components: {
    Price,
    EstimatedPrice,
    UiSlider
  }
};
</script>
<style lang="scss" scoped>
.service-condition {
  & > .container {
    & > * {
      &:last-child {
        border-bottom: unset;
      }
    }

    .header {
      padding: unit(16);

      .primary {
        font-size: unit(22);
        line-height: unit(32);
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: $color-gray-9;
      }

      .secondary {
        margin-top: unit(8);
        font-size: unit(15);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: unit(22);
        letter-spacing: normal;
        color: #878d91;
      }
    }

    .body {
      padding-bottom: 2 * $unit;

      @media screen and (max-width: $site-width-h) and (max-height: 620px) {
        padding-bottom: unit(120);
      }

      & > ul > li {
        padding: 0 unit(16);
        .one-list {
          border-bottom: solid 1px #e0e0e0;
          padding: unit(22) unit(8);
          height: auto;
          transition: all 0.2s ease-out;
        }

        &.activated {
          .tab {
            .value {
              button {
                i {
                  color: $color-black;
                  transform: rotate(180deg);
                  padding: 0.25 * $unit 0 0 0;
                }
              }
            }
          }

          .transitioned-item {
            height: auto;
            padding: 0 -unit(10);
            opacity: 1;
            .vc-pane-container {
              width: 100%;
            }

            & > * {
              height: auto;
            }
          }
        }

        &.readonly {
          opacity: 0.25;
          background: rgba(244, 244, 244, 0.5);
          cursor: not-allowed;
          pointer-events: none;
        }
      }

      .tab {
        display: flex;

        .key {
          flex: 1;
          font-size: unit(16);
          line-height: unit(24);
          font-weight: 600;
          color: #292a2b;
          display: flex;
          align-items: center;
          img {
            margin-right: unit(10);
          }
        }

        .value {
          justify-content: flex-end;
          font-size: unit(16);
          line-height: unit(23);
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          display: flex;
          align-items: center;
          color: $color-gray-9;

          button {
            display: flex;
            align-items: center;
            padding-right: 2.25 * $unit;

            i {
              position: absolute;
              right: 0;
              padding: 0 0 0.25 * $unit 0;
              font-size: 2.25 * $unit;
              -webkit-text-stroke: $color-white 0.25 * $unit;
            }
          }

          &.error {
            color: $color-red;
            text-decoration: underline;
          }
        }

        .text-button-area {
          position: absolute;
          top: 100%;
          z-index: 1;

          button {
            img {
              display: inline-block;
              vertical-align: middle;
              width: unit(20);
              height: auto;
              margin-right: unit(4);
              top: unit(-2);
            }

            width: auto;
            display: inline-block;
            text-align: left;
            font-size: unit(11);
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 100%;
            letter-spacing: normal;
            color: #878d91;
            text-decoration: underline;
          }
        }
      }

      .transitioned-item {
        margin-top: unit(20);
        height: 0;
        transition: all 0.2s ease-out;
        overflow: hidden;
        // width: 14.75*$unit;
        margin: auto;
        opacity: 0;

        & > * {
          height: 0;
        }
        .container {
          padding: 0;

          ul {
            margin-top: unit(20);
            padding: unit(20);
            margin: 0 -unit(10);
            background-color: #f1f5f5;

            .selected {
              border: 1px solid #48d9eb;
              box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12), 2px 6px 12px rgba(0, 0, 0, 0.12);
            }
            .unavailableProduct {
              background-color: #f8fafb;
              color: #a9afb3;
            }

            li {
              height: unit(48);
              padding: unit(12) unit(20);
              background-color: white;
              margin: unit(10) 0;
              border-radius: unit(24);
              display: flex;
              justify-content: space-between;
              align-items: center;

              &.disabled {
                opacity: 0.5;
                pointer-events: none;
              }

              &.message {
                background-color: transparent;
                color: #999;
                font-size: unit(16);
                font-weight: normal;
              }

              .time {
                color: #292a2b;
                font-size: unit(16);
                font-weight: 500;
              }

              .quantity {
                color: #61676c;
                font-size: unit(14);
                font-weight: 400;
              }
            }
          }
        }
        .luggage {
          padding-top: 2 * $unit;

          & > ul > li {
            display: flex;
            border: solid 1px #e9ebee;
            border-radius: 0.167 * $unit;
            height: 5.667 * $unit;

            & + li {
              margin-top: 1.333 * $unit;
            }

            .key {
              flex: 1 1 unit(110);
              display: flex;
              align-items: center;
              font-weight: 500;
              font-size: unit(15);
              line-height: unit(22);
              color: $color-gray-9;
              padding: 0 2 * $unit;

              img {
                display: inline-block;
                width: 2 * $unit;
                height: auto;
                bottom: 0 * $unit;
                margin-right: 0.25 * $unit;
              }
            }

            .value {
              flex: 1 0 auto;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              font-weight: 500;
              font-size: unit(15);
              line-height: unit(22);
              color: $color-gray-9;
              line-height: 1.46;
              letter-spacing: normal;
              padding-right: 2 * $unit;

              button {
                width: 2.333 * $unit;
                height: 2.333 * $unit;
                border: solid 1px #e9ebee;
                border-radius: 0.167 * $unit;
                line-height: 2.333 * $unit;
                flex-grow: 0;
                flex-shrink: 0;
                -webkit-text-stroke: 2.5px $color-white;
                font-size: 1.5 * $unit;
              }

              input[type='tel'] {
                width: 3 * $unit;
                flex-grow: 0;
                flex-shrink: 0;
                line-height: 2.333 * $unit;
                text-align: center;
                color: #1890ff;
                background-color: transparent;
                border: none;
                font-size: 1.125 * $unit;
              }
            }

            &.faded {
              & > * {
                opacity: 0.28;
              }
            }
          }
        }
      }
    }

    .footer_and_message {
      position: fixed;
      bottom: 0;
      width: 100%;
      max-width: $max-content-width;
      margin: 0;

      .footer {
        background: #ffffff;
        box-shadow: 0px 1px 5px #a9afb3;
        border-radius: 30px 30px 0px 0px;
        height: unit(100);
        padding: unit(20) unit(16) env(safe-area-inset-bottom) unit(16);
        z-index: 2;
        margin: 0;

        .text-area {
          flex: 1 1 auto;

          & > * + * {
            margin-top: unit(2);
          }

          .secondary {
            font-size: unit(11);
            line-height: unit(16);
            color: $color-gray-9;

            img {
              display: inline-block;
              width: unit(15);
              height: unit(15);
              vertical-align: top;
              top: unit(-2);
            }
          }

          .primary {
            font-size: unit(18);
            line-height: unit(26);
            color: $color-gray-9;
            font-weight: bold;
          }
        }

        .button-area {
          background: #ced3d6;
          border-radius: 24px;
          text-transform: uppercase;
          color: $color-white;
          font-weight: bold;
          padding: unit(13);
          cursor: not-allowed;
          font-size: unit(14);
          line-height: unit(20);
          @include ellipsis(1);

          &.activated {
            background-color: #48d9eb;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.start-date-time-message {
  color: #878d91;
  font-size: unit(12);
  padding-top: unit(30);
}
.message {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f8fafb;
  width: 90%;
  height: unit(80);
  border-radius: unit(30);
  margin: 0 auto unit(20) auto;
  padding: unit(14) unit(16) unit(14) unit(16);
  gap: unit(10);
  .text-area {
    flex: 1 1 auto;
    line-height: 1.5; //폰트사이즈에 비례해서.
    font-weight: 500;
    font-size: 11; // 0.625rem === 10; unit(브라우저에서는)!
    word-break: break-all;
    word-wrap: break-word;
  }
  .emoji-area {
    flex: 0 0 unit(30);
    width: unit(30);

    .bravo_emoji {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .remove-button {
    flex: 0 0 unit(24);
    width: unit(24);
    height: unit(24);
  }
}
</style>
