<template>
  <div class="page service-product">
    <template>
      <div class="container">
        <div ref="header" class="site-header more-depth" :class="{ 'site-header--white': storeNameHidden }">
          <div class="header">
            <button v-button class="header__button--left" type="button" @click="onClickBack">
              <img
                v-if="$route.query.from === 'ticket'"
                src="/img/icon/header-close-white.svg"
                style="width: 24px; height: 24px; object-fit: contain; margin: auto"
              />
              <template v-else>
                <img v-if="storeNameHidden" src="@/assets/icons/svg/arrow-left.svg" />
                <img v-else src="@/assets/icons/svg/arrow-white.svg" />
              </template>
            </button>

            <div class="title" v-show="storeNameHidden">
              {{ store.store_name_localed }}
            </div>

            <button v-if="$route.query.from !== 'ticket'" v-button class="header__button--right" type="button" @click="openSidebar()">
              <img v-if="storeNameHidden" src="@/assets/icons/svg/hamburger.svg" />
              <img v-else src="@/assets/icons/svg/hamburger-white.svg" />
            </button>
          </div>
        </div>

        <div ref="body" class="page-body">
          <div class="store-images" :style="{ height: carouselHeight + 'px' }">
            <lug-carousel :increment-pager="true" :delay-animation="0.1">
              <lug-carousel-item
                v-for="(resource, index) in serviceProductImages"
                :key="`store-image-${index}`"
                v-viewer="{ toolbar: false, title: false, movable: false }"
              >
                <img
                  class="image"
                  :src="resource.source"
                  :onerror="resource.onError"
                  :style="{
                    objectFit: resource.objectFit
                  }"
                />
              </lug-carousel-item>
            </lug-carousel>
          </div>

          <div class="store-info">
            <div class="section">
              <div class="section-body">
                <!--@TODO: 분기처리 수정-->
                <div v-if="service?.extras?.festival !== 1" ref="store-name" class="store-name" @click="onClickStore">
                  {{ store?.store_name_localed }}
                  <img src="@/assets/icons/svg/icon_arrow_right.svg" />
                </div>

                <div class="store-service" ref="store-service">
                  <!-- {{ $__t(`${service?.product_name}`) }} -->
                  {{ store?.store_name_localed }}
                </div>

                <div class="store-address">
                  <img src="@/assets/icons/svg/icon_pin.svg" class="icon" />
                  <span>
                    {{ store?.store_address_localed }}
                  </span>
                </div>

                <div class="store-service-price">
                  <div class="descript">
                    {{ $__t(`${service?.product_descript ? service?.product_descript : ''}`) }}
                  </div>

                  <div class="price-info">
                    <span class="origin" v-if="isDiscountPrice"
                      >{{ $utils.numberFormat(parseInt(service.product_price) + 1000) }}{{ $__t('원') }}</span
                    >
                    <span class="price">{{ $utils.numberFormat(service.product_price) }}{{ $__t('원') }}</span>
                  </div>
                </div>

                <div class="store-facility">
                  <ul class="facility-list">
                    <li class="facility-item">
                      <button type="button" v-button class="store-info__button" @click="openRef('map-connect-slider')">
                        <img src="@/assets/icons/svg/address.svg" class="icon" />
                        <span>{{ $__t('지도') }}</span>
                      </button>
                    </li>
                    <li class="facility-item">
                      <button type="button" v-button class="store-info__button" @click="$router.push({ name: 'Cs' })">
                        <img src="@/assets/icons/svg/phone.svg" class="icon" />
                        <span>{{ $__t('전화') }}</span>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="section service-descript">
              <div class="card table">
                <div v-if="service?.extras?.festival !== 1" class="title">상세보기</div>
                <div class="content" :class="{ expended: expendedContent }">
                  <!-- <img :src="service?.resources?.detail_image" /> -->
                  <img :src="detailImage" />
                </div>

                <button class="btn-more-content" @click="onClickMoreContentButton">
                  <span>{{ expendedContent ? $__t('상품 설명 줄이기') : $__t('상품 설명 더보기') }}</span>
                </button>
              </div>
            </div>

            <div class="section infomation-use">
              <!-- <h2 class="title">{{ $__t('이용안내') }}</h2> -->
              <h2 class="title">{{ $__t('Notice') }}</h2>

              <div class="how-to-use">
                <div v-if="service?.extras?.festival !== 1" class="title">사용방법</div>
                <!--@TODO: 분기처리 수정-->
                <!--Festival x-->
                <ul v-if="service?.extras?.festival !== 1">
                  <li>• 매장에 방문 후 앱 실행</li>
                  <li>• 이용내역 > 구입한 티켓 화면 접속 > '체크인' 버튼 클릭 > QR 스캔</li>
                  <li>• 구입 서비스 이용</li>
                  <li>• 이용 완료 후 '체크아웃' 버튼 클릭 > QR 스캔</li>
                </ul>
                <!--Festival o-->
                <div v-if="service?.extras?.festival === 1">
                  <!-- <div class="title">{{ $__t('Notice') }}</div> -->
                  <ul>
                    <li>1. {{ $__t('귀중품, 현금 등 고가의 물품을 비롯하여 위법의 소지가 되는 물품은 보관이 불가합니다.') }}</li>
                    <li>2. {{ $__t('음료를 포함한 모든 음식물의 보관이 불가합니다.') }}</li>
                    <li>3. {{ $__t('이용 도중 물품의 임시 수령은 불가합니다. 맡기기 전 잊으신 물건이 없는지 꼭 확인해주세요.') }}</li>
                    <li>4. {{ $__t('맡기려는 물품의 개수와 구매한 보관 티켓 개수가 다를 경우현장에서 추가 구매를 요청할 수 있습니다.') }}</li>
                    <li>5. {{ $__t('상기 조건에 동의하지 않을 경우 물품 보관이 거절될 수 있습니다.') }}</li>
                    <li>6. {{ $__t('지정된 시간 내에 물품 수령을 하지 않을 경우 추가 비용이 발생할 수 있습니다.') }}</li>
                  </ul>
                </div>
              </div>

              <div class="opening-hour">
                <!--Festival x-->
                <div v-if="service?.extras?.festival !== 1" class="card table">
                  <h2 class="title">영업시간</h2>

                  <ul class="table-row" v-for="displayWeek in displayWeeks" :key="displayWeek + 1">
                    <li
                      class="table-cell"
                      v-for="(weekKey, index) in displayWeek"
                      :class="{
                        red: weekKey === 'SA_SUN' || weekKey === 'SA_HOL'
                      }"
                      :key="`weekKey-${index}`"
                    >
                      <div class="day">{{ $__t(weekKey) }}</div>
                      {{ displayTimeTable(weekKey) }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="section policy">
              <h2 class="title">{{ $__t('Cancellation & Refund policy') }}</h2>

              <ul class="policy-list">
                <li>{{ $__t('취소/환불의 경우 럭스테이를 통해서만 가능합니다.') }} </li>
                <li>{{ $__t('미사용 티켓은 티켓이 만료되기 전까지 취소가 가능합니다. (만료 시각: 사용 예정일 익일 1:00 AM)') }}</li>
                <!-- <li>{{ $__t('미사용 상품에 한하여 사용일 1일 전까지 100% 환불 가능합니다.') }} </li> -->
                <!-- <li>{{ $__t('당일 구매 상품의 경우, 취소 및 환불이 불가합니다.') }} </li> -->
                <!-- <li>{{ $__t('제휴점 사정으로 서비스를 이용할 수 없는 경우, 날짜 변경 또는 비용을 환불해 드립니다.') }} </li> -->
              </ul>
            </div>

            <div class="section notice">
              <ul>
                <li>{{ $__t('평일') }}: 10:00 - 17:30 ({{ $__t('점심시간') }} : 12:00 - 13:30)</li>
                <!-- <li>{{ $__t('주말: 1:1 채팅 상담만 가능, 공휴일 휴무') }}</li> -->
              </ul>
            </div>
          </div>

          <div class="page-footer">
            <div class="button-area">
              <button type="button" class="button submit-button activated" @click="onClickSubmitButton">
                {{ $__t('구매하기') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>

    <guide-modal id="condition-modal" ref="condition-modal" :transparent="true">
      <!--@TODO: 분기처리-->
      <!--Festival o-->
      <template v-if="service?.extras?.festival === 1" v-slot:body>
        <ServiceSearchConditionFestival
          @close="closeModal('condition-modal')"
          @submit="onSubmitCondition"
          :store="store"
          :service="service"
        ></ServiceSearchConditionFestival>
      </template>
      <!--Festival x-->
      <template v-else v-slot:body>
        <ServiceSearchCondition
          @close="closeModal('condition-modal')"
          @submit="onSubmitCondition"
          :store="store"
          :service="service"
        ></ServiceSearchCondition>
      </template>
    </guide-modal>

    <!--here-->
    <modal id="alert-modal" ref="alert-modal" submitButtonLabel="확인" @submit="closeRef('alert-modal')">
      <template v-slot:body>
        <div class="text">
          <div class="context">구매 가능한 티켓이 소진되어 해당 수량을 구매할 수 없습니다.</div>
        </div>
      </template>
      <template v-slot:footer>
        <div>
          <button>확인</button>
        </div>
      </template>
    </modal>
    <!--here-->

    <ui-slider id="map-connect-slider" ref="map-connect-slider" :bgClick="true" :background="true">
      <MapConnect :store="store" @close="closeRef('map-connect-slider')" />
    </ui-slider>
  </div>
</template>

<script>
import Vue from 'vue';
import _ from 'lodash';
import utils from '@/utils';
import LugCarousel from '@/components/modules/LugCarousel/Body';
import LugCarouselItem from '@/components/modules/LugCarousel/Item';
import store_facilities from '@/assets/json/store-facilities.json';
import ServiceSearchCondition from '@/components/pages/service-product/conditions/ServiceSearchCondition';
import ServiceSearchConditionFestival from '@/components/pages/service-product/conditions/ServiceSearchConditionFestival';
import Clipboard from 'clipboard';
import BoxSelect from '@/components/includes/Locker/BoxSelect.vue';
import UiSlider from '@/components/modules/Slider/Body.vue';
import MapConnect from '@/components/resources/map/MapConnect.vue';
import LockerBoxSelector from '@/components/resources/locker/LockerReserveBoxSelector.vue';

export default {
  name: 'ServiceProduct',

  props: ['service_id', 'store_id'],

  data() {
    let defaultStartDateTimeString = utils.getClosestValidDateTime(this.$moment().format('YYYY-MM-DD HH:mm'));

    let defaultEndDateTimeString = this.$moment(defaultStartDateTimeString).add(10, 'm').format('YYYY-MM-DD HH:mm');

    return {
      formData: {
        quantity_big: parseInt(this.$route.query.quantity_big) || parseInt(this.$route.params.formData?.quantity_big) || 0,
        quantity_small: parseInt(this.$route.query.quantity_small) || parseInt(this.$route.params.formData?.quantity_small) || 0,
        date_start: {
          label: this.getLabelOfDates(defaultStartDateTimeString),
          value: defaultStartDateTimeString
        },
        date_end: {
          label: this.getLabelOfDates(defaultEndDateTimeString),
          value: defaultEndDateTimeString
        },
        selected_box: {
          value: null
        }
      },

      storeNameHidden: false,
      storeFacilities: store_facilities,
      carouselHeight: 0,
      calculatedPrice: 0,
      headerCovered: false,

      displayWeeks: [
        ['SA_MON', 'SA_TUE'],
        ['SA_WED', 'SA_THU'],
        ['SA_FRI', 'SA_SAT'],
        ['SA_SUN', 'SA_HOL']
      ],

      popupVisibility: {
        map: false,
        share: false
      },

      clipboard: null,

      availableStore: true,
      locker: null,

      selectedPass: null,
      passAgreed: false,
      storePasses: [],

      expendedContent: false
    };
  },

  watch: {
    storeNameHidden: {
      handler(value) {
        this.wni.execute('wnAppChangeStatusBar', {
          'status-bar-style': 'dark',
          'background-color': '#ffffff'
        });
      },
      immediate: true
    }
  },

  created() {},

  async mounted() {
    // console.log('language', localStorage.getItem('lugstay_language'));
    try {
      this.$store.commit('loading/SET_TRUE');

      await this.fetchServiceProduct();
      await this.fetchStore();

      await this.$nextTick();

      setTimeout(() => {
        this.setCarouselHeight();
      }, 0);

      window.addEventListener('resize', this.setCarouselHeight);
      window.addEventListener('scroll', this.toggleHeaderTitleVisibility);

      // for Test
      // this.openModal("condition-modal");
    } catch (e) {
      if (e.message.includes('NO_EXISTS_DATA')) {
        this.$store.commit('alert/ADD_ITEM', {
          message: e.message,
          status: 'error'
        });
      }
    } finally {
      const callbackFuncName = this.$route.query.callback;
      const orderType = this.$route.query.type;

      this.$store.commit('loading/SET_FALSE');

      if (callbackFuncName) {
        if (typeof this[callbackFuncName] === 'function') {
          this.$store.commit('loading/SET_TRUE', { cover: true });
          let query = { ...this.$route.query };
          delete query.callback;
          delete query.type;
          window.history.replaceState({}, '', this.$route.path + utils.generateQueryString(query));
          await this[callbackFuncName](orderType ?? null);
          this.$store.commit('loading/SET_FALSE');
        }
      }
    }
  },

  beforeDestroy() {
    this.toggleHeaderTitleVisibility(true);
    window.removeEventListener('scroll', this.toggleHeaderTitleVisibility);
    window.removeEventListener('resize', this.setCarouselHeight);
  },

  computed: {
    isLoggedIn() {
      return this.$store.state.auth.gid > 0;
    },
    store() {
      return (
        this.$store.getters['stores/GET_ITEM']({
          key: 'id',
          value: this.service?.store_id
        }) || {}
      );
    },

    service() {
      return (
        this.$store.getters['serviceProduct/GET_ITEM']({
          key: 'id',
          value: this.$props.service_id
        }) || {}
      );
    },

    timeTable() {
      return this.store?.time_table || {};
    },

    serviceProductImages() {
      // console.log('### serviceProductImages', this.service, this.service.resources);

      const thumbnailImages = [];
      thumbnailImages.push({
        resource_url: this.service?.resources?.featured_images
      });

      if (this.store?.resources?.thumbnail_image) {
        let thumbnailImage = this.store?.resources?.thumbnail_image;
        if (thumbnailImage.resource_url) {
          thumbnailImages.push(thumbnailImage);
        }
      }

      const detailImages = this.store?.resources?.detail_images || [];
      const directsImages = this.store?.resources?.directions_images || [];
      const resourceImages = [...thumbnailImages, ...detailImages, ...directsImages]
        .filter((resource) => {
          console.log( "#resource", JSON.stringify(resource) );
          return resource.resource_url;
        })
        .map((resource) => {
          return {
            ...resource,
            source: resource.resource_url,
            objectFit: resource.resource_url.includes('default-lugstay') ? 'contain' : 'cover',
            onError: function () {
              this.onerror = null;
              this.src = '/img/default-lugstay-circle.png';
            }
          };
        });

      return resourceImages;
    },
    detailImage() {
      if (localStorage.getItem('lugstay_language') === 'ko') {
        // console.log('this.service', this.service);
        return this.service?.resources?.detail_image?.kr;
      } else {
        return this.service?.resources?.detail_image?.en;
      }
    },

    isDiscountPrice() {
      // @TODO: 임시 설정, 추후 discount 값을 받아서 처리 필요
      return this.service.product_price === 6000;
    }
  },

  methods: {
    fetchStore() {
      return this.$store.dispatch('stores/get', {
        id: parseInt(this.service.store_id)
      });
    },

    fetchServiceProduct() {
      return this.$store.dispatch('serviceProduct/get', {
        id: parseInt(this.$props.service_id)
      });
    },

    fetchReserve(options) {
      return this.$store.dispatch('serviceProduct/create', options);
    },

    displayTimeTable(weekKey) {
      let displayValue = '';
      let timeTable = this.timeTable;
      let timeTableItem = timeTable && timeTable[weekKey];

      if (!timeTable || !timeTableItem) {
        return this.$__t('Day Off');
      }

      displayValue += timeTableItem[0];

      let endHour = timeTableItem[1].split(':')[0];
      let endMin = timeTableItem[1].split(':')[1];

      if (endHour > 24) {
        endHour -= 24;
        if (endHour < 10) endHour = '0' + endHour;
      }

      displayValue += '~' + endHour + ':' + endMin;

      return displayValue;
    },

    openModal(ref) {
      this.$refs[ref].open(ref);
    },

    closeModal(ref) {
      this.$refs[ref].close();
    },

    setCarouselHeight() {
      let height = 0;

      try {
        height = this.$refs.body?.clientWidth * 0.90625;
      } catch (e) {
        height = window.innerWidth > 480 ? 480 * 0.90625 : window.innerWidth * 0.90625;
      }

      this.carouselHeight = height;
    },

    getLabelOfDates(dateString) {
      let date = this.$moment(dateString);
      let weekdays = [
        this.$__t('SSA_SUN'),
        this.$__t('SSA_MON'),
        this.$__t('SSA_TUE'),
        this.$__t('SSA_WED'),
        this.$__t('SSA_THU'),
        this.$__t('SSA_FRI'),
        this.$__t('SSA_SAT')
      ];

      let formattedDate = date.format('MM/DD');
      let day = date.day();

      if (date.format('MM/DD') === this.$moment().format('MM/DD')) {
        formattedDate = this.$__t('{date-time-picker}.today');
      } else {
        formattedDate += ' (' + weekdays[day] + ')';
      }

      return formattedDate;
    },

    toggleHeaderTitleVisibility(clear, e) {
      const $pageHeader = $(this.$refs.header);
      // const $storeName = $(this.$refs['store-name']);
      const $storeService = $(this.$refs['store-service']);

      this.storeNameHidden = $storeService.offset().top + $storeService.outerHeight() < window.scrollY + $pageHeader.outerHeight();
    },

    executeShare(method) {
      let shareURL = '';

      switch (method) {
        case 'naver-blog':
          this.$analytics.logEvent('share store information', {
            'link to': 'naver'
          });
          shareURL = 'http://blog.naver.com/openapi/share?url=' + location.origin + location.pathname;
          break;

        case 'line':
          this.$analytics.logEvent('share store information', {
            'link to': 'line'
          });
          shareURL = 'http://line.me/R/msg/text?' + location.origin + location.pathname;
          break;

        case 'facebook':
          this.$analytics.logEvent('share store information', {
            'link to': 'facebook'
          });
          shareURL = 'https://www.facebook.com/sharer/sharer.php?u=' + location.origin + location.pathname;
          break;
      }

      if (this.wni.isNative) {
        this.wni.execute('wnOpenURL', { url: shareURL });
      } else {
        window.open(shareURL);
      }
    },

    amplitudeTracker(which) {
      let store_location = this.store.store_address.split(' ');

      if (store_location[0] === '경기') store_location = store_location[0] + ' ' + store_location[1];
      else store_location = store_location[0];

      switch (which) {
        case 'created':
          this.$analytics.logEvent('store detail page view', {
            'store id': parseInt(this.store_id),
            'store name': this.store.store_name,
            'store location': store_location
          });
          break;

        case 'reserve':
          this.$analytics.logEvent('booking button', {
            'store id': parseInt(this.store_id),
            'store name': this.store.store_name,
            'store location': store_location
          });
          break;
      }
    },

    openSidebar() {
      this.$parent.toggleSidebar();
    },

    openRef(ref) {
      this.$refs[ref].open();
    },

    closeRef(ref) {
      this.$refs[ref].close();
    },

    onClickStore() {
      this.$router.push({
        name: 'StoreSingle',
        params: { store_id: this.store?.id }
      });
    },

    onClickMoreContentButton() {
      this.$data.expendedContent = !this.$data.expendedContent;
    },

    onClickSubmitButton() {
      if (this.isLoggedIn) {
        this.openModal('condition-modal');
      } else {
        return this.$router.push({
          name: 'AuthLogin',
          query: { redirect: this.$router.currentRoute.path }
        });
      }
    },

    async onSubmitCondition(data) {
      try {
        const reserve = await this.fetchReserve({
          store_id: this.store.uid,
          service_id: this.service.uid,

          product_id: data.product_id,
          start_date: data.start_date,
          end_date: data.end_date,
          quantity: data.request_quantity ? data.request_quantity : 1
        });

        this.closeModal('condition-modal');

        setTimeout(() => {
          this.$router.push({
            name: 'ServiceProductReserve',
            params: {
              order_id: reserve.receipt_id,
              // store_id: this.store.uid,
              store_id: this.service?.store_id,
              service_id: this.service.uid
            }
          });
        }, 0);
      } catch (e) {
        console.error(e);
        this.$store.commit('alert/ADD_ITEM', {
          message: e.message,
          status: 'error'
        });
        // this.openModal('alert-modal');
      }
    },

    onClickAction(action) {
      if (action === 'phone') {
        this.$analytics.logEvent('store call');
        location.href = 'tel:+82-218779727';
      } else if (action === 'bookmark') {
        this.$analytics.logEvent('save store');
        if (!this.$store?.state?.auth?.gid) {
          return this.$router.push({
            name: 'AuthLogin',
            query: {
              redirect: location.origin + '/stores/' + this.store_id,
              queryString: { ...this.formData }
            }
          });
        }
        this.$store
          .dispatch(`stores/${this.isInterestedStore ? 'delete' : 'add'}Interest`, {
            id: parseInt(this.store_id),
            list: 'MyInterestStores'
          })
          .then((res) => {
            if (Boolean(res.interested) === true) {
              this.$store.commit('notice/ADD_ITEM', {
                message: this.$__t('관심상점으로 저장이 완료되었습니다.'),
                icon: '/img/icon-bookmark-blue.svg'
              });
            }
          });
      } else if (['map', 'share'].includes(action)) {
        Object.keys(this.popupVisibility).forEach((o) => (this.popupVisibility[o] = o === action ? true : false));

        setTimeout(() => {
          this.clipboard = new Clipboard('.copy-btn');
          this.clipboard.on('success', (e) => {
            e.clearSelection();
            this.$store.commit('notice/ADD_ITEM', {
              message: this.$__t('복사되었습니다.')
            });
          });
        }, 100);
      }

      return;
    },

    onClickBack() {
      this.$router.back();
    }
  },

  components: {
    LugCarousel,
    LugCarouselItem,
    ServiceSearchCondition,
    ServiceSearchConditionFestival,
    BoxSelect,
    UiSlider,
    MapConnect,
    LockerBoxSelector
  }
};
</script>

<style lang="scss" scoped>
.page.service-product {
  @include container('& > .container', $max-content-width);

  * {
    @include no-selection-highlight;
  }

  & > .container {
    background-color: #f1f5f5;

    .site-header {
      background-color: transparent;
      position: fixed;

      .title {
        margin: unit(20) auto auto auto;
        color: $color-white;
      }

      &--white {
        background-color: $color-white;
        box-shadow: 0px unit(4) unit(12) rgba(0, 0, 0, 0.16), unit(4) unit(8) unit(28) rgba(0, 0, 0, 0.08);

        .title {
          color: $color-black;
        }
      }
    }

    .page-body {
      // store-images
      .store-images {
        .lug-carousel::v-deep {
          .lug-carousel-viewport {
            min-width: min(100vw, $max-content-width);
            height: 100% !important;

            .lug-carousel-list {
              min-width: min(100vw, $max-content-width);
              padding: 0;

              .lug-carousel-item {
                min-width: min(100vw, $max-content-width);
                max-width: $max-content-width;
                height: 100%;

                img {
                  object-position: center;
                }
              }
            }

            .lug-carousel-pager-zoomable {
              & > * {
                margin: 0 unit(7);
                bottom: unit(30);
                width: unit(8) !important;
                height: unit(8) !important;
              }

              .on {
                background-color: #1890ff;
                border-radius: 50% !important;
              }
            }
          }
        }

        .image {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        &::before {
          content: '';
          position: absolute;
          width: 100%;
          height: 37%;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) -21.26%, rgba(0, 0, 0, 0) 68.81%);
          z-index: 1;
        }
      }

      //   .usage-time-area {
      //     height: 0;

      //     .time-banner-area {
      //       bottom: 0;
      //       width: calc(100% - #{unit(32)});
      //       transform: translateY(-50%);
      //       margin: auto;
      //     }
      //   }

      // store-info
      .store-info {
        padding: 0;

        .section {
          & + .section {
            margin-top: unit(8);
          }

          padding: unit(20) unit(16);
          background: $color-white;

          .section-body {
            //   border-bottom-color: #f1f5f5;
            border-bottom-color: red;
            border-bottom-width: unit(8);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: left;
            gap: unit(16);

            .store-name {
              display: flex;
              font-weight: 700;
              font-size: unit(16);
              line-height: 1.38;
              letter-spacing: -0.006em;
            }
            .store-service {
              font-weight: 600;
              font-size: unit(20);
              line-height: 28px;
            }
            .store-address {
              display: flex;
              flex-direction: row;
              color: #61676c;
              font-size: unit(12);
              font-weight: 400;
              .icon {
                display: inline-block;
              }
            }
            .store-service-price {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: flex-end;

              > .descript {
                width: 100%;
                font-size: unit(15);
                font-weight: 600;
                padding-bottom: unit(3);
              }

              > .price-info {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-end;

                > .origin {
                  text-decoration: line-through;
                  font-size: unit(14);
                  font-weight: 400;
                  line-height: 1;
                  color: #292a2b;
                  opacity: 0.8;
                }

                > .price {
                  font-size: unit(24);
                  font-weight: 700;
                  color: #292a2b;
                  line-height: 1.5;
                }
              }
            }

            .facility-list {
              display: flex;
              justify-content: space-around;
              align-items: center;
              width: 100%;
              overflow-x: auto;
              scroll-behavior: smooth;
            }

            .facility-item {
              // background: rgba(0, 143, 255, 0.06);
              text-align: center;
              border-radius: unit(8);
              margin-right: unit(8);
              display: inline-block;
              width: auto;
              padding: unit(2) unit(6);
              font-size: unit(12);
              font-weight: 400;
              line-height: 1.5;
              letter-spacing: -0.006em;
              color: #61676c;

              .store-info__button {
                display: flex;
                justify-content: space-between;
                align-items: center;
              }
              .button-area {
                display: flex;
                gap: unit(8);

                .store-info__button {
                  width: unit(40);
                  height: unit(40);
                  border: 1px solid #e1e4e6;
                  box-sizing: border-box;
                  border-radius: unit(100);
                  background: $color-white;

                  .icon {
                    color: pink;
                    width: unit(24);
                    height: unit(24);
                    object-fit: contain;
                    line-height: unit(40);
                    margin: auto;
                  }
                }
              }
            }

            .section-footer {
              margin-top: unit(12);

              &:last-of-type {
                margin: 0;
              }
            }
          }

          &.service-descript {
            .card {
              .title {
                font-weight: 600;
                font-size: unit(20);
              }

              .content {
                position: relative;
                &:not(.expended) {
                  overflow: hidden;
                  max-height: 300px;
                }
              }
              .btn-more-content {
                // position: absolute;
                // bottom: 0;
                // left: 0;
                // right: 0;
                // z-index: 1;
                background: #fff;
                padding: unit(8);
                margin: unit(8) auto 0 auto;
                width: 100%;

                span {
                  color: #4d4dff;
                  font-size: unit(12);
                  font-weight: bold;
                }
              }
            }
          }
          &.infomation-use {
            h2 {
              font-weight: bold;
              font-size: unit(20);
              // color: pink;
            }

            .how-to-use {
              padding: unit(20) 0;
              .title {
                font-weight: 600;
                font-size: unit(16);
                line-height: unit(24);
                color: #292a2b;
                margin-bottom: unit(5);
              }
              ul {
                padding: unit(6);
                font-weight: 400;
                font-size: unit(14);
                line-height: unit(20);
                .strong {
                  font-weight: 700;
                }
                li {
                  padding-left: unit(5);
                  text-indent: unit(-13);
                }
              }
            }
            .opening-hour {
              .title {
                font-weight: 600;
                font-size: unit(16);
                line-height: unit(24);
                color: #292a2b;
                margin-bottom: unit(10);
              }
              .context {
                font-size: unit(14);
                line-height: unit(20);
              }
              //   .time-table {
              //     padding: unit(16);

              .table-row {
                .table-cell {
                  padding: unit(10) unit(5);
                  text-align: left;
                  font-weight: 400;
                  font-size: unit(14);
                  line-height: 1.43;
                  letter-spacing: -0.006em;

                  .day {
                    text-align: left;
                    font-weight: 500;
                    font-size: unit(14);
                    line-height: 1.43;
                    letter-spacing: -0.006em;
                    // color: #292a2b;
                  }

                  &.red {
                    color: #ff5d5d;
                  }
                }

                &:first-of-type {
                  .table-cell {
                    padding-top: 0;
                  }
                }

                &:last-of-type {
                  .table-cell {
                    padding-bottom: 0;
                  }
                }
              }
              //   }
            }
          }
          &.policy {
            h2 {
              font-weight: bold;
              font-size: unit(20);
              padding: 0 unit(20) unit(18) 0;
            }
            .policy-list {
              padding: unit(6);
              font-weight: 400;
              font-size: unit(14);
              line-height: unit(20);
              li {
                &::before {
                  content: ' • ';
                  font-size: unit(14);
                  padding: unit(0) unit(4);
                }
                
                padding-bottom: unit(3);
              }
            }
          }

          &.time-table {
            margin-top: unit(12);
            padding: unit(16);

            .title {
              font-size: unit(16);
              font-weight: bold;
              line-height: 1.38;
              letter-spacing: -0.006em;
              padding-left: unit(12);
            }

            .card {
              background: #ffffff;
              border-radius: unit(20);
              padding: unit(22) unit(16);
            }
          }

          &.facility {
            padding: unit(16);

            .title {
              font-size: unit(16);
              font-weight: bold;
              line-height: 1.38;
              letter-spacing: -0.006em;
              padding-left: unit(12);
            }

            .card {
              background: #ffffff;
              border-radius: unit(20);
              padding: unit(18) unit(16);

              .facility-list {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                .facility-item {
                  width: calc((100% - #{unit(12)}) / 2);
                  display: flex;
                  align-items: flex-start;
                  margin: unit(12) 0;

                  img {
                    flex: 0 0 unit(18);
                    width: unit(18);
                    height: auto;
                    display: inline-block;
                    top: 0;

                    & + * {
                      margin-left: unit(4);
                    }
                  }

                  .text {
                    flex: 1;
                    font-size: unit(14);
                    line-height: 150%;
                    color: #a9afb3;
                  }

                  &.activated {
                    .text {
                      color: $color-black;
                    }
                  }
                }
              }
            }
          }

          &.notice {
            padding: unit(16);
            color: #878d91;
            font-weight: 400;
            font-size: unit(14);
            line-height: unit(20);
          }

          &.locker-price {
            padding: unit(16);

            .title {
              font-size: unit(16);
              font-weight: bold;
              line-height: 1.38;
              letter-spacing: -0.006em;
              padding-left: unit(12);
            }

            .table {
              border-collapse: collapse;
              table-layout: fixed;
              width: 100%;

              .table-header {
                .table-cell {
                  height: unit(44);
                  vertical-align: middle;
                  // border: solid 1px #dbdbdb;
                }
              }

              .table-row {
                & > .table-cell:first-of-type {
                  min-width: unit(70);
                }

                &:last-child {
                  border-bottom: solid 1px #eaeeef;
                }
              }

              .table-cell {
                border-top: solid 1px #eaeeef;
                border-right: solid 1px #eaeeef;
                vertical-align: middle;
                font-weight: 400;
                font-size: unit(10);
                line-height: 1.5;
                height: unit(44);
                color: #61676c;
                text-align: center;
                letter-spacing: -0.006em;
                word-wrap: break-word;
                word-break: keep-all;
                padding: unit(8) unit(2);

                &:first-of-type {
                  border-left: solid 1px #eaeeef;
                }

                &.bg-gray {
                  background: #f1f5f5;
                }

                &.black {
                  color: $color-black;
                  font-weight: 500;
                }
              }
            }
          }

          &.locker-guide {
            padding: unit(16);

            .title {
              font-size: unit(16);
              font-weight: bold;
              line-height: 1.38;
              letter-spacing: -0.006em;
              padding-left: unit(12);
            }

            .locker-guide__text {
              font-weight: 400;
              font-size: unit(14);
              line-height: 1.43;

              strong {
                font-weight: bold;
              }

              & + .locker-guide__text {
                margin-top: unit(28);
              }
            }
          }

          .card {
            // padding: unit(22) unit(16);
            background-color: $color-white;
            border-radius: unit(20);

            &.table {
              display: table;
              table-layout: fixed;
              width: 100%;

              .table-row {
                display: table-row;
              }

              .table-cell {
                display: table-cell;
              }
            }

            &.flex {
              display: flex;
            }
          }
        }
      }
    }

    // page-footer
    .page-footer {
      position: -webkit-sticky;
      position: sticky;
      width: 100%;
      max-width: $max-content-width;
      bottom: 0;
      margin-top: unit(44);
      box-shadow: 0px unit(1) unit(5) #a9afb3;
      border-radius: unit(10) unit(10) 0px 0px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: unit(8) unit(16) unit(24) unit(16);

      background: #ffffff;
      box-shadow: 0px unit(4) unit(12) rgba(0, 0, 0, 0.16), unit(4) unit(8) unit(28) rgba(0, 0, 0, 0.08);
      border-radius: unit(20) unit(20) 0px 0px;
      z-index: 2;

      .text-area {
        display: flex;
        align-items: flex-end;
        width: 100%;
        justify-content: space-between;
        gap: unit(16);
        flex: 0 0 unit(40);

        * {
          font-size: unit(11);
          line-height: unit(16);
        }

        .primary {
          color: $color-gray-9;

          strong {
            font-weight: 500;
            color: $color-black;
            font-weight: 700;
            font-size: unit(16);
            line-height: 1.38;
            /* or 138% */
            letter-spacing: -0.006em;
          }
        }

        .secondary {
          font-size: unit(14);
          font-weight: 400;
          line-height: 1.43;
          text-decoration-line: underline;
          color: #61676c;
        }
      }

      .button-area {
        display: flex;
        align-items: stretch;
        gap: unit(12);
        width: 100%;
        height: unit(50);
        margin-top: unit(14);

        .button {
          &.bookmark-button {
            flex: 0 0 unit(46);
            background: $color-white;
            border: 1px solid #e1e4e6;
            border-radius: unit(16);

            img {
              width: unit(24);
              height: unit(24);
              object-fit: contain;
              margin: auto;
            }
          }

          &.submit-button {
            flex: 1;
            padding: unit(15) unit(16);
            color: $color-white;
            background: #a9afb3;
            border-radius: unit(16);
            font-size: unit(14);
            line-height: 1.43;
            font-weight: bold;

            &.activated {
              background-color: #48d9eb;
              border-radius: 24px;
              cursor: pointer;
            }
          }
        }
      }

      &.locker {
        display: block;
        height: unit(86);
        padding: unit(20) unit(16);

        .button-area {
          margin: 0;

          .submit-button {
            background: $color-primary;
          }
        }
      }
    }
  }
}

.site-header {
  .header__button--right {
    img {
      width: unit(24) !important;
      height: unit(24) !important;
      object-fit: contain;
      margin: auto;
    }
  }
}

.lug-carousel::v-deep {
  .lug-carousel-slide-bar {
    width: unit(120) !important;
    bottom: unit(14);
    border-radius: unit(18);

    span {
      border-radius: 0;

      &.on {
        border-radius: unit(18);
      }
    }
  }
}
</style>
