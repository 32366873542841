<template>
  <div id="Condition-page" class="service-condition">
    <div class="container">
      <div class="header"></div>
      <div class="body">
        <ul>
          <!-- 1. 날짜 -->
          <li :class="{ activated: activatedListItem === 'date' }" ref="date_start">
            <div class="one-list">
              <div class="tab" @click="onClickListItem('date')">
                <div class="key">
                  <img src="@/assets/icons/svg/calendar.svg" />
                  <span>{{ displaySelectedDate }}</span>
                </div>
                <div class="value">
                  <button type="button">
                    <i class="fa fa-angle-down"></i>
                  </button>
                </div>
              </div>
              <div class="transitioned-item">
                <div class="container">
                  <ui-day-picker
                    calendarType="date"
                    v-model="formData.date.value"
                    :min="formData.date.min"
                    :max="formData.date.max"
                    @select="onSelectDate"
                  />
                </div>
              </div>
            </div>
          </li>
          <!-- 2. 수량-->
          <li :class="{ activated: activatedListItem === 'time' }" ref="date_end">
            <div class="one-list">
              <!--제목-->
              <div class="tab" @click="onClickListItem('time')">
                <div class="key">
                  <img src="@/assets/icons/svg/icon_carrier.svg" />
                  <span>{{ $__t(`${displaySelectedItemQuantity}`) }}</span>
                </div>
                <div class="value">
                  <button type="button">
                    <i class="fa fa-angle-down"></i>
                  </button>
                </div>
              </div>
              <!--클릭시 안에 내용-->
              <div class="transitioned-item">
                <div class="container">
                  <div class="item">
                    <div class="request_quantity">
                      <div class="button">
                        <button class="minus" @click="onClickMinus"><i class="fas fa-minus"></i></button>
                        <span>{{ request_quantity }}</span>
                        <button class="plus" @click="onClickPlus" :disabled="!available"><i class="fas fa-plus"></i></button>
                      </div>
                    </div>
                    <div v-if="!available" class="quantity_alert">{{ $__t('보관 가능수량이 가득 차 구매할 수 없습니다.') }}</div>
                    <div class="quantity_notice">
                      <p>{{ $__t('물품 하나당 수량 1개로 설정 해주세요.') }}</p>
                      <p>{{ $__t('ex) 핸드백 1개, 쇼핑백 1개 보관시 보관 티켓 2개 구매') }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="footer_and_message">
        <div class="footer">
          <button
            class="button-area"
            :class="{
              activated: request_quantity && available
            }"
            type="button"
            @click="onClickSubmit"
            :disabled="!request_quantity || !available"
          >
            {{
              request_quantity === 0
                ? $__t('결제하기')
                : $__t(`{price}원 결제하기`, {
                    price: $utils.numberFormat(service.product_price * request_quantity)
                  })
            }}
          </button>
        </div>
      </div>
    </div>

    <guide-modal id="priceLuggageModal" ref="priceLuggageModal" :noCloseButton="true" :noBgClick="true" :external-close="true">
      <template v-slot:body>
        <price />
      </template>
    </guide-modal>

    <ui-slider id="estimated-price-slider" ref="estimated-price-slider" :bgClick="true"> </ui-slider>
  </div>
</template>

<script>
import _ from 'lodash';
import dayjs from 'dayjs';
import Price from '@/components/pages/_/Price';
import EstimatedPrice from '@/components/includes/EstimatedPrice';
import UiSlider from '@/components/modules/Slider/Body.vue';

export default {
  components: {
    Price,
    EstimatedPrice,
    UiSlider
  },
  props: ['store', 'service'],
  computed: {
    displaySelectedItemQuantity() {
      if (this.request_quantity === 0) {
        return '보관 수량 선택';
      }

      const product = this.request_quantity;
      return `${product} 개`;
    },
    displaySelectedDate() {
      if (!this.formData.date.value) {
        return '날짜 선택';
      }
      let result = '';
      result = this.$moment(this.formData.date.value).format('YYYY.MM.DD');
      let day = this.$moment(this.formData.date.value).day();
      switch (day) {
        case 1:
          if(localStorage.getItem('lugstay_language') === 'ko') {
            result = result + ' (월)';
          } else {
            result = result + ' (Mon)';
          }
          break;

        case 2:
        if(localStorage.getItem('lugstay_language') === 'ko') {
            result = result + ' (화)';
          } else {
            result = result + ' (Tue)';
          }
          break;
        case 3:
        if(localStorage.getItem('lugstay_language') === 'ko') {
            result = result + ' (수)';
          } else {
            result = result + ' (Wed)';
          }
          break;
        case 4:
        if(localStorage.getItem('lugstay_language') === 'ko') {
            result = result + ' (목)';
          } else {
            result = result + ' (Thur)';
          }
          break;
        case 5:
        if(localStorage.getItem('lugstay_language') === 'ko') {
            result = result + ' (금)';
          } else {
            result = result + ' (Fri)';
          }
          break;
        case 6:
        if(localStorage.getItem('lugstay_language') === 'ko') {
            result = result + ' (토)';
          } else {
            result = result + ' (Sat)';
          }
          break;
        case 7:
        if(localStorage.getItem('lugstay_language') === 'ko') {
            result = result + ' (일)';
          } else {
            result = result + ' (Sun)';
          }
          break;
      }
      this.bookingDate = result;
      return result;
    },

    productSelectedIndex() {
      if (!this.$data.formData.product.value) {
        return -1;
      }

      return this.$data.formData.product.value?.index;
    },

    displayProduct() {
      let items = { ...this.products[0] };
      return items;
    }
  },

  created() {},
  async mounted() {
    await this.onSelectDate();
  },

  methods: {
    //here
    minValidDate(valid_dates) {
      let startDate = _.first(valid_dates);
      let endDate = _.last(valid_dates);
      let now = dayjs().format('YYYY-MM-DD');
      // let now = "2023-04-17";

      if (endDate < now) {
        return now;
      }

      if (now < startDate) {
        return startDate;
      }

      let currentDateIndex = valid_dates.indexOf(now);
      if (currentDateIndex == -1) {
        return startDate;
      }

      let minDate = valid_dates[currentDateIndex];
      return minDate;
    },
    maxValidDate(valid_dates) {
      let endDate = _.last(valid_dates);
      let now = dayjs().format('YYYY-MM-DD');
      // let now = "2023-04-17";
      if (endDate < now) {
        return now;
      }
      return endDate;
    },
    //here
    onClickListItem(item) {
      switch (item) {
        case 'date':
          this.activatedListItem = this.activatedListItem === 'date' ? '' : 'date';
          break;

        case 'time':
          this.activatedListItem = this.activatedListItem === 'time' ? '' : 'time';
          break;
      }
    },

    fetchProducts(options) {
      return this.$store.dispatch('serviceProduct/getProducts', {
        page: 1,
        size: 10,
        ...options
      });
    },

    async onSelectDate(day) {
      this.request_quantity = 0;
      this.available = true;
      if (!day) {
        //1. 원래 생각한 방법. 틀림. 10/2에도 10/1로 된다.
        // day = '2022-10-01';

        //2. 이 경우는, 10/2 지나면 다시 10/1로 입력된다.
        // if (this.$moment().format('YYYY-MM-DD') === '2022-10-02') {
        //   day = '2022-10-02';
        // } else {
        //   day = '2022-10-01';
        // }

        //1.오늘 날짜가 서버에서 주는 최대 기간이 넘은 경우 예약불가안내
        //2. 오늘날짜가 서버에서 주는 최대 기간이 넘지 않은 경우
        //if 오늘날짜가 최소날짜 초과인 경우 : 오늘날짜로
        //else 오늘날짜가 최소날짜 이하인 경우 : 최소날짜
        // console.log('this.$moment()', this.$moment());
        // if (this.$moment(this.$moment().format('YYYY-MM-DD')).isAfter(`${this.service.extras.day[1]}`)) {
        //   alert('예약이 불가능합니다.');
        //   return;
        // } else {
          if (this.$moment(this.$moment().format('YYYY-MM-DD')).isAfter(`${this.service.extras.day[0]}`)) {
            day = this.$moment().format('YYYY-MM-DD');
          } else {
            day = this.service.extras.day[0];
          }
        // }
      }
      const date = this.$moment(day).format('YYYY-MM-DD');
      const { items } = await this.fetchProducts({
        service_id: this.service.uid,
        day: date
      });
      // console.log('onSelect items', items);
      this.$data.formData.product.value = null;
      this.$data.products = items.map((item) => {
        const timeComponents = item.time_table.split(',');

        this.activatedListItem = this.activatedListItem === 'date' ? '' : 'date';

        return {
          ...item,
          product_id: item.uid,
          start_date: `${date} ${timeComponents[0]}`,
          end_date: `${date} ${timeComponents[1]}`,
          quantity: item.stock_remain
        };
      });
      // console.log('this.$data.products', this.$data.products);
    },

    onSelectProduct(product) {
      if (product.disabled) {
        // @TODO: 사용할 수 없음을 안내
        return;
      }

      this.$data.formData.product.value = product;
      // this.activatedListItem = this.activatedListItem === 'time' ? '' : 'time';
    },

    onClickSubmit: _.debounce(function () {
      const selectedProduct = this.$data.products[0];

      const product = {
        request_quantity: this.request_quantity,
        product_id: selectedProduct.product_id,
        start_date: selectedProduct.start_date,
        end_date: selectedProduct.end_date,
      };

      console.log('onClickSubmit product', product);

      // @MEWO: 해당 Condition 의 역할은 예약하기 위한 데이터를 얻어오는것 까지임
      this.$emit('submit', product);
    }, 100),

    onClickMinus() {
      if (this.request_quantity === 0) {
        return;
      }
      this.request_quantity = this.request_quantity - 1;
      if (this.request_quantity > this.displayProduct.stock_remain) {
        this.available = false;
      } else {
        this.available = true;
      }
    },
    onClickPlus() {
      this.request_quantity = this.request_quantity + 1;
      if (this.request_quantity > this.displayProduct.stock_remain) {
        this.available = false;
      }
    }
  },
  data() {
    const validDates = this.service.extras.day;
    // const validDates = ['2022-10-01', '2022-10-02'];
    const min = this.minValidDate(validDates);
    const max = this.maxValidDate(validDates);

    return {
      activatedListItem: 'time',
      products: [],
      formData: {
        date: {
          value: this.$moment(),
          //현재날짜가 행사 마지막날이면 minDate가 그 날짜, 그게 아니면 행사 첫 날짜
          // min: this.$moment(
          //   this.$moment().format('YYYY-MM-DD') === this.service.extras.day[1] ? this.service.extras.day[1] : this.service.extras.day[0]
          // ).startOf('day'),
          // max: this.$moment(`${this.service.extras.day[1]}`).endOf('day')
          min: this.$moment(min),
          max: this.$moment(max)
        },
        product: {
          value: null
        }
      },
      request_quantity: 0,
      available: true
    };
  }
};
</script>
<style lang="scss" scoped>
.service-condition {
  & > .container {
    & > * {
      &:last-child {
        border-bottom: unset;
      }
    }

    .header {
      padding: unit(16);

      .primary {
        font-size: unit(22);
        line-height: unit(32);
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: $color-gray-9;
      }

      .secondary {
        margin-top: unit(8);
        font-size: unit(15);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: unit(22);
        letter-spacing: normal;
        color: #878d91;
      }
    }

    .body {
      padding-bottom: 2 * $unit;

      @media screen and (max-width: $site-width-h) and (max-height: 620px) {
        padding-bottom: unit(120);
      }

      & > ul > li {
        padding: 0 unit(16);
        .one-list {
          border-bottom: solid 1px #e0e0e0;
          padding: unit(22) unit(8);
          height: auto;
          transition: all 0.2s ease-out;
        }

        &.activated {
          .tab {
            .value {
              button {
                i {
                  color: $color-black;
                  transform: rotate(180deg);
                  padding: 0.25 * $unit 0 0 0;
                }
              }
            }
          }

          .transitioned-item {
            height: auto;
            padding: 0 -unit(10);
            opacity: 1;
            .vc-pane-container {
              width: 100%;
            }

            & > * {
              height: auto;
            }
          }
        }

        &.readonly {
          opacity: 0.25;
          background: rgba(244, 244, 244, 0.5);
          cursor: not-allowed;
          pointer-events: none;
        }
      }

      .tab {
        display: flex;

        .key {
          flex: 1;
          font-size: unit(16);
          line-height: unit(24);
          font-weight: 600;
          color: #292a2b;
          display: flex;
          align-items: center;
          img {
            margin-right: unit(10);
          }
        }

        .value {
          justify-content: flex-end;
          font-size: unit(16);
          line-height: unit(23);
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          display: flex;
          align-items: center;
          color: $color-gray-9;

          button {
            display: flex;
            align-items: center;
            padding-right: 2.25 * $unit;

            i {
              position: absolute;
              right: 0;
              padding: 0 0 0.25 * $unit 0;
              font-size: 2.25 * $unit;
              -webkit-text-stroke: $color-white 0.25 * $unit;
            }
          }

          &.error {
            color: $color-red;
            text-decoration: underline;
          }
        }

        .text-button-area {
          position: absolute;
          top: 100%;
          z-index: 1;

          button {
            img {
              display: inline-block;
              vertical-align: middle;
              width: unit(20);
              height: auto;
              margin-right: unit(4);
              top: unit(-2);
            }

            width: auto;
            display: inline-block;
            text-align: left;
            font-size: unit(11);
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 100%;
            letter-spacing: normal;
            color: #878d91;
            text-decoration: underline;
          }
        }
      }

      .transitioned-item {
        margin-top: unit(20);
        height: 0;
        transition: all 0.2s ease-out;
        overflow: hidden;
        // width: 14.75*$unit;
        margin: auto;
        opacity: 0;

        & > * {
          height: 0;
        }
        .container {
          padding: 0;
          .item {
            .request_quantity {
              background-color: #f1f5f5;
              height: unit(88);
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              .button {
                background-color: white;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                height: unit(48);
                width: 90%;
                border-radius: 24px;
                padding: 0 unit(20);
                font-size: unit(16);
                button {
                  font-size: unit(14);
                  width: unit(24);
                  height: unit(24);
                  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
                  &.minus {
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                    border-bottom-left-radius: 24px;
                    border-bottom-right-radius: 24px;
                    font-size: unit(10);
                  }
                  &.plus {
                    border-top-left-radius: 24px;
                    border-top-right-radius: 24px;
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                    font-size: unit(10);
                  }
                }
              }
            }
            .quantity_notice {
              margin-top: unit(15);
              font-size: unit(14);
              text-align: left;
              line-height: 1.429; //line-height는 em안써도됨
            }
            .quantity_alert {
              // color: rgb(230, 20, 20);
              color: #ff4646;
              // font-size: 0.875rem;
              font-size: unit(14);
              text-align: left;
            }
          }

          ul {
            margin-top: unit(20);
            padding: unit(20);
            margin: 0 -unit(10);
            background-color: #f1f5f5;

            .selected {
              border: 1px solid #48d9eb;
              box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12), 2px 6px 12px rgba(0, 0, 0, 0.12);
            }
            .unavailableProduct {
              background-color: #f8fafb;
              color: #a9afb3;
            }

            li {
              height: unit(48);
              padding: unit(12) unit(20);
              background-color: white;
              margin: unit(10) 0;
              border-radius: unit(24);
              display: flex;
              justify-content: space-between;
              align-items: center;

              &.disabled {
                opacity: 0.5;
                pointer-events: none;
              }

              &.message {
                background-color: transparent;
                color: #999;
                font-size: unit(16);
                font-weight: normal;
              }

              .time {
                color: #292a2b;
                font-size: unit(16);
                font-weight: 500;
              }

              .quantity {
                color: #61676c;
                font-size: unit(14);
                font-weight: 400;
              }
            }
          }
        }
        .luggage {
          padding-top: 2 * $unit;

          & > ul > li {
            display: flex;
            border: solid 1px #e9ebee;
            border-radius: 0.167 * $unit;
            height: 5.667 * $unit;

            & + li {
              margin-top: 1.333 * $unit;
            }

            .key {
              flex: 1 1 unit(110);
              display: flex;
              align-items: center;
              font-weight: 500;
              font-size: unit(15);
              line-height: unit(22);
              color: $color-gray-9;
              padding: 0 2 * $unit;

              img {
                display: inline-block;
                width: 2 * $unit;
                height: auto;
                bottom: 0 * $unit;
                margin-right: 0.25 * $unit;
              }
            }

            .value {
              flex: 1 0 auto;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              font-weight: 500;
              font-size: unit(15);
              line-height: unit(22);
              color: $color-gray-9;
              line-height: 1.46;
              letter-spacing: normal;
              padding-right: 2 * $unit;

              button {
                width: 2.333 * $unit;
                height: 2.333 * $unit;
                border: solid 1px #e9ebee;
                border-radius: 0.167 * $unit;
                line-height: 2.333 * $unit;
                flex-grow: 0;
                flex-shrink: 0;
                -webkit-text-stroke: 2.5px $color-white;
                font-size: 1.5 * $unit;
              }

              input[type='tel'] {
                width: 3 * $unit;
                flex-grow: 0;
                flex-shrink: 0;
                line-height: 2.333 * $unit;
                text-align: center;
                color: #1890ff;
                background-color: transparent;
                border: none;
                font-size: 1.125 * $unit;
              }
            }

            &.faded {
              & > * {
                opacity: 0.28;
              }
            }
          }
        }
      }
    }

    .footer_and_message {
      position: fixed;
      bottom: 0;
      width: 100%;
      max-width: $max-content-width;
      margin: 0;

      .footer {
        background: #ffffff;
        box-shadow: 0px 1px 5px #a9afb3;
        border-radius: 30px 30px 0px 0px;
        height: unit(100);
        padding: unit(20) unit(16) env(safe-area-inset-bottom) unit(16);
        z-index: 2;
        margin: 0;

        .text-area {
          flex: 1 1 auto;

          & > * + * {
            margin-top: unit(2);
          }

          .secondary {
            font-size: unit(11);
            line-height: unit(16);
            color: $color-gray-9;

            img {
              display: inline-block;
              width: unit(15);
              height: unit(15);
              vertical-align: top;
              top: unit(-2);
            }
          }

          .primary {
            font-size: unit(18);
            line-height: unit(26);
            color: $color-gray-9;
            font-weight: bold;
          }
        }

        .button-area {
          background: #ced3d6;
          border-radius: 24px;
          text-transform: uppercase;
          color: $color-white;
          font-weight: bold;
          padding: unit(13);
          cursor: not-allowed;
          font-size: unit(14);
          line-height: unit(20);
          @include ellipsis(1);

          &.activated {
            background-color: #48d9eb;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.start-date-time-message {
  color: #878d91;
  font-size: unit(12);
  padding-top: unit(30);
}
.message {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f8fafb;
  width: 90%;
  height: unit(80);
  border-radius: unit(30);
  margin: 0 auto unit(20) auto;
  padding: unit(14) unit(16) unit(14) unit(16);
  gap: unit(10);
  .text-area {
    flex: 1 1 auto;
    line-height: 1.5; //폰트사이즈에 비례해서.
    font-weight: 500;
    font-size: 11; // 0.625rem === 10; unit(브라우저에서는)!
    word-break: break-all;
    word-wrap: break-word;
  }
  .emoji-area {
    flex: 0 0 unit(30);
    width: unit(30);

    .bravo_emoji {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .remove-button {
    flex: 0 0 unit(24);
    width: unit(24);
    height: unit(24);
  }
}
</style>
